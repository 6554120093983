import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { format } from "date-fns";

// Icons
import { FaCogs, FaEye, FaUsers } from "react-icons/fa";
import { GrMoney } from "react-icons/gr";
import { MdEdit } from "react-icons/md";

// API
import api from "../../../services/api";

// Hooks
import { useAuth } from "../../../hooks/auth";

// Common Types
import UrlsAddress from "../../../types/urlsAddress";
import { IBranchData } from "../../../types/internalConsultantsTypes";

import {
    Container,
    Title,
    TableContent,
    BadgeGold,
    BadgeSilver,
    BadgeBronze,
    ActionsCell,
} from "./styles";

const BranchesGrid: React.FC = () => {
    const [branchesData, setBranchesData] = useState<IBranchData[]>([]);
    const [processingBranchesGrid, setProcessingBranchesGrid] = useState(false);

    const fetchData = useCallback(async () => {
        setProcessingBranchesGrid(true);

        await api
            .get("internalConsultant/get-internal-branches")
            .then(response => {
                const { data } = response;

                if (data && data.success && data.data) {
                    const branches: IBranchData[] = data.data;

                    setBranchesData(branches);
                }
            });

        setProcessingBranchesGrid(false);
    }, []);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getActions = (branch: IBranchData): JSX.Element => {
        const manageCreditsLink = (
            <Link
                to={`${UrlsAddress.INTERNAL_MANAGE_CREDITS_BRANCH}/${branch.id}`}
                title="Manage MOJ Credits"
            >
                <GrMoney size={20} />
            </Link>
        );

        const staffLink = (
            <Link
                to={`${UrlsAddress.INTERNAL_STAFF.replace("/:branch?", "")}/${
                    branch.id
                }`}
                title="Manage MOJ Credits"
            >
                <FaUsers size={20} />
            </Link>
        );

        const editLink = (
            <Link
                to={`${UrlsAddress.INTERNAL_BRANCH_DETAIL}/${branch.id}`}
                title="Edit"
            >
                <MdEdit size={20} />
            </Link>
        );

        let formattedActions: JSX.Element[] = [];

        formattedActions = [manageCreditsLink, staffLink, editLink];

        return <ActionsCell>{formattedActions}</ActionsCell>;
    };

    const getTableColumns = useMemo(() => {
        const tableColumns = [
            {
                name: "id",
                selector: "id",
                omit: true,
            },
            {
                name: "Name",
                selector: "name",
                center: true,
                grow: 2,
            },
            {
                name: "Email",
                selector: "contactEmail",
                center: true,
            },
            {
                name: "Contact Number",
                selector: "contactNumber",
                center: true,
            },
            {
                name: "Gold Cred.",
                selector: "mojGoldCreditsQuantity",
                center: true,
                cell: (row: IBranchData) => (
                    <BadgeGold>{row.mojGoldCreditsQuantity}</BadgeGold>
                ),
            },
            {
                name: "Silver Cred.",
                selector: "mojSilverCreditsQuantity",
                center: true,
                cell: (row: IBranchData) => (
                    <BadgeSilver>{row.mojSilverCreditsQuantity}</BadgeSilver>
                ),
            },
            {
                name: "Bronze Cred.",
                selector: "mojBronzeCreditsQuantity",
                center: true,
                cell: (row: IBranchData) => (
                    <BadgeBronze>{row.mojBronzeCreditsQuantity}</BadgeBronze>
                ),
            },
            {
                name: "",
                cell: (row: IBranchData) => getActions(row),
                // compact: true,
                width: "120px",
                // maxWidth: "60px",
                // minWidth: "60px",
            },
        ];

        return tableColumns;
    }, []);

    const getBranchesData = useMemo(() => {
        return branchesData;
    }, [branchesData]);

    return (
        <Container>
            <Title>Branches</Title>
            <TableContent>
                <DataTable
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    noHeader
                    highlightOnHover
                    columns={getTableColumns}
                    data={getBranchesData}
                    pagination
                    progressPending={processingBranchesGrid}
                    responsive
                />
            </TableContent>
        </Container>
    );
};

export default BranchesGrid;
