import React, { useRef, useCallback } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
// import { useHistory } from "react-router-dom";

// Icons
import { FiUser, FiMail, FiPhone, FiMessageSquare } from "react-icons/fi";

// Toast
import { toast } from "react-toastify";

// API
import api from "../../../../services/api";

// Hooks
// import { useToast } from "../../../../hooks/toast";
import { useLoader } from "../../../../hooks/loader";

// Common Components
import Input from "../../../../components/Input";
import InputWithLabel from "../../../../components/InputWithLabel";
import InputNumberWithLabel from "../../../../components/InputNumberWithLabel";
import TextArea from "../../../../components/TextArea";
import TextAreaWithLabel from "../../../../components/TextAreaWithLabel";
import Button from "../../../../components/Button";
import getValidationErrors from "../../../../utils/getValidationErrors";

import Section from "../../Section";

// Styles
import { Container } from "./styles";

interface IContactUsFormData {
    email: string;
    name: string;
    phone: string;
    message: string;
}

const ContactUs: React.FC = () => {
    // const [processingRequest, setProcessingRequest] = useState(false);

    const formRef = useRef<FormHandles>(null);

    // const { addToast } = useToast();
    const { showLoader, hideLoader } = useLoader();
    // const history = useHistory();

    const sendMessage = useCallback(async ({ email, name, phone, message }) => {
        await api.post("Contact/sendEmail", {
            email,
            name,
            phone,
            message,
        });
    }, []);

    const handleSubmit = useCallback(
        async (data: IContactUsFormData) => {
            try {
                formRef.current?.setErrors({});

                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    name: Yup.string().required("Name is required"),
                    email: Yup.string()
                        .required("E-mail address is required")
                        .email("E-mail address invalid"),
                    message: Yup.string().required("Message is required"),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                showLoader();

                await sendMessage({
                    email: data.email,
                    name: data.name,
                    phone: data.phone,
                    message: data.message,
                });

                formRef.current?.reset();

                toast.success(
                    "Thanks for your message. We'll get in touch with you as soon as possible!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );

                hideLoader();
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                hideLoader();

                // addToast({
                //     type: "error",
                //     title: "Error when authenticating",
                //     description:
                //         "There was an error signing in, check your credentials!",
                // });
                toast.error(
                    "There was an error signing in, check your credentials!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            }
        },
        [sendMessage, showLoader, hideLoader],
    );

    return (
        <>
            <Section
                isHero={false}
                id="contact-us"
                title="Send us a message"
                backgroundColor="#eee"
                titleBackgroundColor="#b2d235"
                titleColor="#333"
            >
                <Container>
                    <div className="jss423">
                        <div className="jss422">
                            <div className="form-content">
                                <p>
                                    You can contact us with anything related to
                                    our Service. We'll get in touch with you as
                                    soon as possible.
                                </p>
                                <Form ref={formRef} onSubmit={handleSubmit}>
                                    <InputWithLabel
                                        name="name"
                                        icon={FiUser}
                                        label="Name"
                                        placeholder="Name *"
                                    />
                                    <InputWithLabel
                                        name="email"
                                        icon={FiMail}
                                        label="E-mail"
                                        placeholder="E-mail *"
                                    />
                                    <InputNumberWithLabel
                                        name="phone"
                                        icon={FiPhone}
                                        label="Contact Number"
                                        // placeholder="optional"
                                    />
                                    <TextAreaWithLabel
                                        name="message"
                                        icon={FiMessageSquare}
                                        label="Message"
                                        placeholder="Message *"
                                        rows={5}
                                    />
                                    <Button type="submit">Send</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Container>
            </Section>
        </>
    );
};

export default ContactUs;
