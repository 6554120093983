import React, {
    useCallback,
    useRef,
    useState,
    useEffect,
    useMemo,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

// Icons
import { FiMail, FiUser } from "react-icons/fi";
import { FaMapMarkerAlt, FaRegBuilding, FaPhoneAlt } from "react-icons/fa";

// Toast
import { toast } from "react-toastify";

// Service
import api from "../../../services/api";

// Hooks
import { useAuth } from "../../../hooks/auth";
// import { useToast } from "../../../hooks/toast";
import { useLoader } from "../../../hooks/loader";

// Common Types
import ActionTypes from "../../../types/userActions";

// Common Components
import InputWithLabel from "../../InputWithLabel";
import InputMaskWithLabel from "../../InputMaskWithLabel";
import InputNumberWithLabel from "../../InputNumberWithLabel";
import Can from "../../Can";

import ButtonLoader from "../../ButtonLoader";

// Utils
import getValidationErrors from "../../../utils/getValidationErrors";
import { convertDateToString } from "../../../utils/dateUtilities";

// Common Types
import UrlsAddress from "../../../types/urlsAddress";

// Styles
import { Container, Content, Title } from "./styles";

// Interfaces
interface IProfileFormData {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    birthDate: string;
    contactNumber: string;
    streetAddress: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
    idCountry: string | null;
    countryName: string;
    company: ICompanyDTO;
    // initialCountry: number;
}

interface ICompanyDTO {
    name: string;
}

interface ICountryData {
    id: string;
    shortName: string;
    officialName: string;
}

interface ICountriesOptions {
    value: string;
    label: string;
}

const ConsultantProfile: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const [processingRequest, setProcessingRequest] = useState(false);
    // const [countries, setCountries] = useState<ICountriesOptions[]>([]);
    const [newZealandId, setNewZealandId] = useState("");

    const [candidateData, setCandidateData] = useState<
        IProfileFormData | undefined
    >();

    const { user, isUserAllowed } = useAuth();
    // const { addToast } = useToast();
    const { showLoader, hideLoader } = useLoader();
    const history = useHistory();

    const fetchData = useCallback(async () => {
        if (!isUserAllowed(ActionTypes.CONSULTANT_READ_CONSULTANT_DETAILS)) {
            // history.push("/access-forbidden");
            history.push(UrlsAddress.ACCESS_FORBIDDEN);
        } else {
            showLoader("Processing...");

            const { data } = await api.get("country/GetNewZealand");
            let idNZ = "00000000-0000-0000-0000-000000000000";

            if (data && data.success) {
                idNZ = data.data.id as string;
                setNewZealandId(idNZ);
            }

            await api
                .get("company")
                .then(response => {
                    const { data } = response;

                    if (data && data.success) {
                        const consultant: IProfileFormData = data.data;

                        if (consultant.birthDate) {
                            const parsedDate = new Date(consultant.birthDate);

                            consultant.birthDate = convertDateToString(
                                parsedDate,
                            );
                        }

                        setCandidateData(consultant);
                    }
                })
                .catch(() => {
                    // handle error
                    toast.error("Error when loading Customer data", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });

            hideLoader();
        }
    }, [showLoader, hideLoader, history, isUserAllowed]);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNewZealandId = useMemo(() => {
        return newZealandId;
    }, [newZealandId]);

    const handleSubmit = useCallback(
        async (data: IProfileFormData) => {
            try {
                formRef.current?.setErrors({});
                // To validate a whole object, it's a good
                // practice create an schema validation
                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required("E-mail is required")
                        .email("E-mail is invalid"),
                    firstName: Yup.string().required("First name is required"),
                    contactNumber: Yup.string().required(
                        "Contact number is required",
                    ),
                    streetAddress: Yup.string().required(
                        "Street address is required",
                    ),
                    suburb: Yup.string().required("Suburb is required"),
                    city: Yup.string().required("City is required"),
                    state: Yup.string().required("State is required"),
                    postcode: Yup.string().required("Postcode is required"),
                    idCountry: Yup.string().required("Country is required"),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                setProcessingRequest(true);

                const formData = {
                    ...data,
                    id: user.id,
                    idCountry: getNewZealandId,
                    company: null,
                };

                await api.put(`company/UpdateConsultant/${user.id}`, formData);

                // success message
                // addToast({
                //     type: "success",
                //     title: "Profile updated!",
                //     description:
                //         "Your profile information has been updated successfully!",
                // });
                toast.success(
                    "Your profile information has been updated successfully!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                // addToast({
                //     type: "error",
                //     title: "Error when updating profile",
                //     description:
                //         "An error occurred when updating your profile. Please, try again!",
                // });
                toast.error(
                    "An error occurred when updating your profile. Please, try again!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            } finally {
                setProcessingRequest(false);
            }
        },
        [user.id, getNewZealandId],
    );

    return (
        <Container>
            <Title>
                <h3>Profile</h3>
            </Title>

            <Content>
                {candidateData ? (
                    <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        initialData={candidateData}
                    >
                        <InputWithLabel
                            label="Company Name"
                            name="company.name"
                            icon={FaRegBuilding}
                            readOnly
                        />
                        <InputWithLabel
                            name="email"
                            label="E-mail"
                            icon={FiMail}
                            readOnly
                        />
                        <InputWithLabel
                            name="firstName"
                            icon={FiUser}
                            label="First Name"
                            placeholder="First Name *"
                        />
                        <InputWithLabel
                            name="middleName"
                            icon={FiUser}
                            label="Middle Name"
                            placeholder="Middle Name"
                        />
                        <InputWithLabel
                            name="lastName"
                            icon={FiUser}
                            label="Last Name"
                            placeholder="Last Name"
                        />
                        <InputNumberWithLabel
                            name="contactNumber"
                            icon={FaPhoneAlt}
                            label="Contact number"
                            placeholder="Contact number *"
                        />

                        <InputWithLabel
                            name="streetAddress"
                            icon={FaMapMarkerAlt}
                            label="Street Address"
                            placeholder="PO Box/Street Address *"
                        />
                        <InputWithLabel
                            name="suburb"
                            icon={FaMapMarkerAlt}
                            label="Suburb"
                            placeholder="Suburb *"
                        />
                        <InputWithLabel
                            name="city"
                            icon={FaMapMarkerAlt}
                            label="City/Town"
                            placeholder="City/Town *"
                        />
                        <InputWithLabel
                            name="state"
                            icon={FaMapMarkerAlt}
                            label="Region"
                            placeholder="Region *"
                        />
                        <InputMaskWithLabel
                            name="postcode"
                            icon={FaMapMarkerAlt}
                            label="Postcode"
                            placeholder="Postcode *"
                            mask="9999"
                        />

                        {/* <Select
                            name="idCountry"
                            placeholder="Country *"
                            icon={FaMapMarkerAlt}
                            isLoading={false}
                            data={countries}
                            noOptionsMessage={() => "No country found!"}
                        /> */}
                        <InputWithLabel
                            name="idCountry"
                            label="Country"
                            icon={FaMapMarkerAlt}
                            value="New Zealand"
                            readOnly
                        />

                        <Can
                            action={
                                ActionTypes.CONSULTANT_UPDATE_CONSULTANT_DETAILS
                            }
                            yes={() => (
                                <>
                                    <ButtonLoader
                                        processingRequest={processingRequest}
                                        type="submit"
                                    >
                                        Save changes
                                    </ButtonLoader>
                                </>
                            )}
                        />
                    </Form>
                ) : (
                    <h1>Loading...</h1>
                )}
            </Content>
        </Container>
    );
};

export default ConsultantProfile;
