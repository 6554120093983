import React, { useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";

interface ITargetProps {
    target: number;
}

const ScrollTopArrow = ({ target }: ITargetProps): JSX.Element => {
    const [showScroll, setShowScroll] = useState(false);

    const checkScrollTop = (): void => {
        if (!showScroll && window.pageYOffset > target) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= target) {
            setShowScroll(false);
        }
    };

    const scrollTop = (): void => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    window.addEventListener("scroll", checkScrollTop);

    return (
        <FaArrowCircleUp
            className="scrollTop"
            onClick={scrollTop}
            style={{
                display: showScroll ? "flex" : "none",
                height: "40px",
                position: "fixed",
                bottom: "20px",
                alignItems: "center",
                justifyContent: "center",
                // zIndex: "1000",
                zIndex: 1000,
                cursor: "pointer",
                color: "rgb(178, 210, 53)",
                right: "0px",
                marginRight: "20px",
                fontSize: "40px",
            }}
        />
    );
};

export default ScrollTopArrow;
