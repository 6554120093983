import axios from "axios";

const api = axios.create({
    baseURL:
        // PROD API
        // "https://y1nfx96k59.execute-api.ap-southeast-2.amazonaws.com/Prod/v1/",

        // STAGING API
        "https://kyjdoojimi.execute-api.ap-southeast-2.amazonaws.com/Prod/v1/",

    // LOCAL API
    // "https://localhost:44381/v1/",
});

export default api;
