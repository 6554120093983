import React, {
    useRef,
    useState,
    useCallback,
    forwardRef,
    useImperativeHandle,
    useEffect,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { parse, isValid } from "date-fns";

// Icons
import { FiUser } from "react-icons/fi";
import { FaInfoCircle, FaBirthdayCake, FaMapMarkerAlt } from "react-icons/fa";

// Hooks
import { useMOJ, IStepBirthDetailFormData } from "../../../hooks/moj";

// Common Components
import InputWithLabel from "../../../components/InputWithLabel";
import InputMaskWithLabel from "../../../components/InputMaskWithLabel";
import Select from "../../../components/Select";
import RadioInput from "../../../components/RadioInput";

// Utils
import getValidationErrors from "../../../utils/getValidationErrors";
import { convertDateToString } from "../../../utils/dateUtilities";

// Styles
import { Container, Content, Warning } from "./styles";

export interface IBirthDetailsMethods {
    // isValid(): boolean;
    validate(): void;
    returnBirthDetailsData(): IStepBirthDetailFormData;
}

interface IRadioOption {
    id: string;
    value: string;
    label: string;
}

const StepBirthDetail: React.RefForwardingComponent<
    IBirthDetailsMethods,
    IStepBirthDetailFormData
> = ({ children }, ref) => {
    const formRef = useRef<FormHandles>(null);

    const [birthDetailsData, setBirthDetailsData] = useState<
        IStepBirthDetailFormData | undefined
    >();
    // const [isFormValid, setIsFormValid] = useState(false);

    // const [selectedGender, setSelectedGender] = useState(0);
    // const [maleSelected, setMaleSelected] = useState(false);
    // const [femaleSelected, setFemaleSelected] = useState(false);

    const radioOptions: IRadioOption[] = [
        { id: "1", value: "1", label: "Male" },
        { id: "2", value: "2", label: "Female" },
    ];

    const { moj, allCountries, updateBirthDetails } = useMOJ();

    useEffect(() => {
        if (moj) {
            const birthDetail: IStepBirthDetailFormData = {
                birthDate: moj.birthDate
                    ? convertDateToString(new Date(moj.birthDate))
                    : "",
                idBirthCountry: moj.idBirthCountry,
                birthCity: moj.birthCity,
                gender: moj.gender,
            };

            // if (birthDetail.gender) {
            //     setSelectedGender(birthDetail.gender);
            //     birthDetail.gender === 1
            //         ? setMaleSelected(true)
            //         : setFemaleSelected(true);
            // }

            setBirthDetailsData(birthDetail);
        }
    }, [moj]);

    function validate(): void {
        formRef.current?.submitForm();
    }

    function returnBirthDetailsData(): IStepBirthDetailFormData {
        return birthDetailsData!;
    }

    useImperativeHandle(ref, () => ({
        isValid,
        validate,
        returnBirthDetailsData,
    }));

    const yupCustomDateValidator = (data: string): boolean => {
        if (data) {
            const parsedDate = parse(data, "dd/MM/yyyy", new Date());

            if (isValid(parsedDate)) {
                return true;
            }
        }

        return false;
    };

    const yupGenderValidator = (data: string): boolean => {
        if (data === "1" || data === "2") {
            return true;
        }

        return false;
    };

    const handleValidation = useCallback(
        async (data: IStepBirthDetailFormData) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    // email: Yup.string()
                    //     .required("E-mail is required")
                    //     .email("E-mail is invalid"),
                    // firstName: Yup.string().required("First Name is required"),
                    // lastName: Yup.string().test(
                    //     "Last Name",
                    //     "Last Name is required",
                    //     value => yupLastNameValidator(value),
                    // ),
                    // contactNumber: Yup.string().required(
                    //     "Contact number is required",
                    // ),
                    // mojPreviousNames: Yup.array(
                    //     Yup.object({
                    //         firstName: Yup.string().required(
                    //             "First name is required",
                    //         ),
                    //     }),
                    // ),

                    // birthDate: moj.birthDate,
                    // birthDate: Yup.string().required("Birth data is required"),
                    birthDate: Yup.string().test(
                        "Date of Birth",
                        "Date is invalid",
                        value => yupCustomDateValidator(value),
                    ),
                    // idBirthCountry: moj.idBirthCountry,
                    idBirthCountry: Yup.string().required(
                        "Country is required",
                    ),
                    // birthCity: moj.birthCity,
                    birthCity: Yup.string().required("City/Town is required"),
                    // gender: Yup.string().required("Gender is required"),
                    gender: Yup.string().test(
                        "Gender",
                        "Gender is required",
                        value => yupGenderValidator(value),
                    ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                await updateBirthDetails(data);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    // setIsFormValid(false);
                }
            }
        },
        [updateBirthDetails],
    );

    return (
        <Container>
            <Content>
                {birthDetailsData && (
                    <Form
                        ref={formRef}
                        onSubmit={handleValidation}
                        initialData={birthDetailsData}
                    >
                        <Warning>
                            <FaInfoCircle />
                            <span>
                                Please make sure the date of birth you enter
                                here matches with your identification document
                                you upload in step 4
                            </span>
                        </Warning>
                        <InputMaskWithLabel
                            name="birthDate"
                            icon={FaBirthdayCake}
                            label="Date of Birth"
                            placeholder={`e.g. 31/12/${new Date().getFullYear()}`}
                            mask="99/99/9999"
                        />
                        <Select
                            name="idBirthCountry"
                            placeholder="Country you were born in *"
                            icon={FaMapMarkerAlt}
                            // isLoading={loadingCountry}
                            data={allCountries}
                            noOptionsMessage={() => "No country found!"}
                        />
                        <InputWithLabel
                            name="birthCity"
                            icon={FiUser}
                            label="City/Town"
                            placeholder="City/Town you were born in"
                        />

                        <RadioInput
                            name="gender"
                            options={radioOptions}
                            inline
                        />
                    </Form>
                )}
            </Content>
        </Container>
    );
};

export default forwardRef(StepBirthDetail);
